import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
// sample page routing
const Reports = Loadable(lazy(() => import('../views/pages/app/Reports')));

const StudentsPage = Loadable(lazy(() => import('../views/pages/app/Students')));
const TrainingPage = Loadable(lazy(() => import('../views/pages/app/Training')));
const TrainingCustomizePage = Loadable(lazy(() => import('../views/pages/app/TrainingCustomize')));
const TrainerPage = Loadable(lazy(() => import('../views/pages/app/Trainers')));
//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {

    return (
        <Route>
            <MainLayout>
                <Switch>
                    <AuthGuard>
                        <Route path="/dashboard" component={DashboardDefault} />

                        <Route path="/reports" component={Reports} />
                        <Route path="/students" component={StudentsPage} />
                        <Route path="/training" component={TrainingPage} />
                        <Route path="/training-customize" component={TrainingCustomizePage} />
                        <Route path="/trainer" component={TrainerPage} />

                        <Redirect from='/' to="/dashboard" />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
