// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconBallFootball, IconSoccerField, IconUsers, IconFileAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome: IconBrandChrome,
    IconHelp: IconHelp,
    IconSitemap: IconSitemap,
    IconBallFootball: IconBallFootball,
    IconSoccerField: IconSoccerField,
    IconUsers:IconUsers,
    IconFileAnalytics:IconFileAnalytics
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    title: 'Database',
    children: [
        {
            id: 'students-page',
            title: 'Students',
            type: 'item',
            url: '/students',
            icon: icons['IconUsers'],
            breadcrumbs: false
        },
        {
            id: 'trainers-page',
            title: 'Trainers',
            type: 'item',
            url: '/trainer',
            icon: icons['IconBallFootball'],
            breadcrumbs: false
        },
        {
            id: 'training-page',
            title: 'Training',
            type: 'item',
            url: '/training',
            icon: icons['IconSoccerField'],
            breadcrumbs: false
        },
        {
            id: 'reports-page',
            title: 'Reports',
            type: 'item',
            url: '/reports',
            icon: icons['IconFileAnalytics'],
            breadcrumbs: false
        }
    ]
};
