import React from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';

//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
    const account = useSelector((state) => state.account)
    const { isLoggedIn } = account

    return (
        <Switch>
            <React.Fragment>
                {!isLoggedIn &&
                    <LoginRoutes />
                }
                {isLoggedIn &&
                    <MainRoutes />
                }
            </React.Fragment>
        </Switch>
    );
};

export default Routes;
